
:root {
  --send-bg: #0B93F6;
  --send-color: white;
  --receive-bg: #E5E5EA;
  --receive-text: black;
  --page-background: white;
  /*max-width: 550px;*/
}

.messages_root {
  max-width: 600px;
  margin: auto;
}

body {
	font-family: "Helvetica Neue", Helvetica, sans-serif;
	/*font-size: 20px;*/
	font-weight: normal;
  /*max-width: 550px;*/
	margin: 50px auto;
	margin-top: 0px;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
}

div[type='message'] {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  word-wrap: break-word;

}

.MuiCardHeader-root {
  background-color: rgb(248, 248, 248);
}

p {
  display: table;
  /* max-width: 255px; */
  word-wrap: break-word;
  margin: 0 0 2px;
  line-height: 20px;
  position: relative;
	padding: 10px 20px;
  border-radius: 1em;
  flex-direction: row;
}

p:before, p:after {
    content: "";
		position: absolute;
    bottom: 0;
    height: 25px;
}

p:first-child {
  border-top-right-radius: 1em;
  border-top-left-radius: 1em;
}

p:last-child {
  border-bottom-left-radius: 1em;
  margin-bottom: 8px;
}

.img_attachment {
	max-width: 350px;
}

.dtheader {
  font-variant: small-caps;
  font-size: x-small;
}

.send {
	color: var(--send-color); 
	background: var(--send-bg);
	align-self: flex-end;
  text-align: right;
  max-width: 430px;
}
		
.send:last-child::before {
		right: -7px;
    width: 20px;
    background-color: var(--send-bg);
		border-bottom-left-radius: 16px 14px;
}

.send:last-child::after {
		right: -26px;
    width: 26px;
    background-color: var(--page-background);
		border-bottom-left-radius: 10px;
}

.receive {
	background: var(--receive-bg);
	color: black;
  align-self: flex-start;
  text-align: left;
  max-width: 430px;
}

.receive:last-child::before {
		left: -7px;
    width: 20px;
    background-color: var(--receive-bg);
		border-bottom-right-radius: 16px 14px;
}

.receive:last-child::after {
		left: -26px;
    width: 26px;
    background-color: var(--page-background);
		border-bottom-right-radius: 10px;
}

.send-icon {
  margin: 0 auto;
  max-width: 420px;
  position: relative;
  display: block;
  border-radius: 50%;
  border: 4px solid #e5e5ea;
  width: 32px;
  height: 32px;
  position: absolute;
  align-content: center;
  bottom: 5px;
  z-index: 1;
  left: 7px;
  color: var(--send-bg);
  background-color: var(--send-bg);
}
  
.send-avatar {
    background-color: var(--send-bg)
}

.receive-icon {
  margin: 0 auto;
  max-width: 420px;
  position: relative;
  display: block;
  border-radius: 50%;
  border: 4px solid #e5e5ea;
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 5px;
  z-index: 1;
  right: 5px;
}

